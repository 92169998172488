<template>
  <div>
    <!-- <div class="tmHeader__header_small">{{ header }}</div> -->
    <show-case :sku="NBpastaCards" />
  </div>
</template>

<script>
import ShowCase from "../components/ShowCase.vue";

import NBpastaMilk from "../assets/NUTBERRY/NBpasta/NB-pasta-milk-350.jpg";
import NBpastaChoco from "../assets/NUTBERRY/NBpasta/NB-pasta-350.jpg";

export default {
  name: "NutberryPasta",
  components: {
    ShowCase,
  },
  data() {
    return {
      header: "Ореховая паста с добавлением какао",
      NBpastaCards: [
        {
          picture: NBpastaMilk,
          text: "NUTBERRY Ореховая паста с добавлением какао и молока 350 г",
          id: 1,
          description:
            "Состав: сахар, растительный жир (фракции рафинированных и дезодорированных подсолнечного и хлопкового масел), сухая молочная сыворотка, паста из ядер фундука, сухое цельное молоко, какао-порошок, эмульгатор (соевый лецитин), ароматизатор идентичный натуральному («Ванилин»).<br>Пищевая ценность в 100 г: белки 7,0г, углеводы 52,0г, из них сахара 48,0г; жиры 35,1г, из них насыщенные 10,1г; клетчатка 1,0г, натрий 0,31г.<br>Энергетическая ценность 552 Ккал.<br>Хранить в сухом месте при температуре от +17С до +21С и относительной влажности воздуха не более 75%. Срок годности 18 месяцев.<br>Страна изготовитель: Турция",
        },
        {
          picture: NBpastaChoco,
          text: "NUTBERRY Ореховая паста с добавлением какао 350 г",
          id: 2,
          description:
            "Состав: сахар, растительный жир (фракции рафинированных и дезодорированных подсолнечного и хлопкового масел), паста из ядер фундука, какао-порошок, сухая молочная, сыворотка, сухое цельное молоко, эмульгатор (соевый лецитин), ароматизатор идентичный натуральному («Ванилин»).<br>Пищевая ценность в 100 г: белки 6,0г, углеводы 52,0г, из них сахара 43,0г; жиры 36,3г, из них насыщенные 12,0г; клетчатка 3,0г.<br>Энергетическая ценность 558 Ккал.<br>Хранить в сухом месте при температуре от +15С до +21С и относительной влажности воздуха не более 75%. Срок годности 18 месяцев.<br>Страна изготовитель: Турция",
        },
      ],
    };
  },
};
</script>
